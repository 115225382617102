<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="680px"
      persistent
      overlay-color="transparent"
      :eager="true"
    >
      <v-form ref="form_protocol" v-model="valid">
        <v-card max-height="100vh">
          <Loading :value="loading" />

          <div>
            <v-system-bar dark color="primary">
              <v-icon class="mr-1">mdi-pencil</v-icon>
              <small><strong class="text-uppercase">{{$t('COMMON.MODIFY')}}</strong></small>
              <v-spacer/>
              <v-icon
                small
                class="clickable"
                @click="closeDialog"
              >
                mdi-close
              </v-icon>
            </v-system-bar>

            <v-toolbar
              color="transparent"
              flat
              dense
              height="60px"
            >
              <v-toolbar-title>{{$t('FEED.PROTOCOL_TITLE')}}</v-toolbar-title>
              <v-spacer />
              <v-btn
                x-small
                fab
                depressed
                color="error"
                class="mr-4"
                @click="closeDialog"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-btn
                rounded
                depressed
                color="success"
                :disabled="!valid"
                @click="apply"
              >
                <v-icon class="mr-2">mdi-check</v-icon>
                {{$t('COMMON.APPLY')}}
              </v-btn>
            </v-toolbar>

            <div class="toolbar-separator primary ml-4 mr-4"/>
          </div>

          <div class="pa-6 pt-0 scroll" style="max-height: calc(80vh - 61px)">

            <v-row class="mt-4">
              <v-col cols="8">
                <p>{{$t('FEED.FIELD_PROTOCOL')}}</p>
                <v-radio-group
                  v-model="protocol"
                  class="mt-0"
                  @change="$emit('input', {protocol: $event})"
                >
                  <v-radio
                    v-for="(item, i) in protocolItems"
                    :key="'prot'+i"
                    :label="item.text"
                    :value="item.value"
                  />
                </v-radio-group>
              </v-col>
            </v-row>


            <!-- HTTP -->
            <div v-if="protocol === 'http'">
              <p class="mt-4">{{$t('FEED.PROTOCOL_PARAMS_TEXT')}}</p>
              <v-row>
                <v-col cols="9" class="pr-0">
                  <v-text-field
                    v-model="protocol_params.url"
                    @change="$emit('input', {protocol_params: {url: $event}})"
                    :label="$t('FEED.FIELD_PROTOCOL_PARAMS_URL')"
                    :placeholder="$t('FEED.FIELD_PROTOCOL_PARAMS_URL_PLACEHOLDER')"
                    :rules="[$rules.required]"
                    outlined
                    required
                    dense
                  />
                </v-col>
                <v-col cols="3" class="pb-0">
                  <v-select
                    v-model="protocol_params.method"
                    @change="$emit('input', {protocol_params: {method: $event}})"
                    :items="protocolMethodItems"
                    :label="$t('FEED.FIELD_PROTOCOL_PARAMS_METHOD')"
                    :placeholder="$t('FEED.FIELD_PROTOCOL_PARAMS_METHOD')"
                    :rules="[$rules.required]"
                    required
                    outlined
                    dense
                  />
                </v-col>
              </v-row>

              <v-row class="mt-0 mb-0">
                <v-col cols="12" class="pt-0 pb-0">
                  <v-checkbox
                    class="mt-0"
                    v-model="protocolNeedAuth"
                    :label="$t('FEED.FIELD_PROTOCOL_PARAMS_NEED_AUTH')"
                    :placeholder="$t('FEED.FIELD_PROTOCOL_PARAMS_NEED_AUTH')"
                  />
                </v-col>
              </v-row>
              <template v-if="protocolNeedAuth">
                <v-row class="mt-0">
                  <v-col cols="6" class="pt-0 pb-0">
                    <v-text-field
                      v-model="protocol_params.user"
                      @change="$emit('input', {protocol_params: {user: $event}})"
                      :label="$t('FEED.FIELD_PROTOCOL_PARAMS_USER')"
                      :placeholder="$t('FEED.FIELD_PROTOCOL_PARAMS_USER')"
                      clearable
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col cols="6" class="pt-0 pb-0">
                    <v-text-field
                      v-model="protocol_params.password"
                      @change="$emit('input', {protocol_params: {password: $event}})"
                      :label="$t('FEED.FIELD_PROTOCOL_PARAMS_PASSWORD')"
                      :placeholder="$t('FEED.FIELD_PROTOCOL_PARAMS_PASSWORD')"
                      type="password"
                      clearable
                      outlined
                      dense
                    />
                  </v-col>
                </v-row>
                <p>{{$t('COMMON.OR')}}</p>
                <v-row class="mt-0 mb-0">
                  <v-col cols="12" class="pt-0 pb-0">
                    <v-text-field
                      v-model="protocol_params.auth"
                      @change="$emit('input', {protocol_params: {auth: $event}})"
                      :label="$t('FEED.FIELD_PROTOCOL_PARAMS_AUTH')"
                      :placeholder="$t('FEED.FIELD_PROTOCOL_PARAMS_AUTH')"
                      clearable
                      outlined
                      dense
                    />
                  </v-col>
                </v-row>
              </template>
              <v-row class="mt-0 mb-0">
                <v-col cols="12" class="pt-0 pb-0">
                  <v-checkbox
                    v-model="protocolNeedData"
                    :label="$t('FEED.FIELD_PROTOCOL_PARAMS_NEED_DATA')"
                    :placeholder="$t('FEED.FIELD_PROTOCOL_PARAMS_NEED_DATA')"
                    class="mt-0"
                  />
                </v-col>
              </v-row>
              <template v-if="protocolNeedData">
                <v-row class="mt-0 mb-0">
                  <v-col cols="12" class="pt-0 pb-0">
                    <v-textarea
                      v-model="protocol_params.data"
                      @change="$emit('input', {protocol_params: {data: $event}})"
                      :label="$t('FEED.FIELD_PROTOCOL_PARAMS_QUERY_DATA')"
                      :placeholder="$t('FEED.FIELD_PROTOCOL_PARAMS_QUERY_DATA')"
                      outlined
                    />
                  </v-col>
                </v-row>
              </template>
            </div>

            <!-- FTP -->
            <div v-if="protocol === 'ftp'">
              <p class="mt-4">{{$t('FEED.PROTOCOL_PARAMS_TEXT')}}</p>
              <v-row>
                <v-col cols="9">
                  <v-text-field
                    v-model="protocol_params.host"
                    @change="$emit('input', {protocol_params: {host: $event}})"
                    :label="$t('FEED.FIELD_PROTOCOL_PARAMS_HOST')"
                    :placeholder="$t('FEED.FIELD_PROTOCOL_PARAMS_HOST')"
                    clearable
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="protocol_params.port"
                    @change="$emit('input', {protocol_params: {port: $event}})"
                    type="number"
                    maxlength="5"
                    :label="$t('FEED.FIELD_PROTOCOL_PARAMS_PORT')"
                    :placeholder="$t('FEED.FIELD_PROTOCOL_PARAMS_PORT')"
                    clearable
                    outlined
                    dense
                  />
                </v-col>
              </v-row>

              <v-row class="mt-0 mb-0">
                <v-col cols="12" class="pt-0 mt-0">
                  <v-text-field
                    v-model="protocol_params.path"
                    @change="$emit('input', {protocol_params: {path: $event}})"
                    :label="$t('FEED.FIELD_PROTOCOL_PARAMS_PATH')"
                    :placeholder="$t('FEED.FIELD_PROTOCOL_PARAMS_PATH')"
                    clearable
                    outlined
                    dense
                  />
                </v-col>
              </v-row>

              <v-row class="mt-0 mb-0">
                <v-col cols="12" class="pt-0 mt-0">
                  <v-checkbox
                    v-model="protocol_params.secure"
                    @change="$emit('input', {protocol_params: {secure: $event}})"
                    :label="$t('FEED.FIELD_PROTOCOL_PARAMS_SECURE')"
                    :placeholder="$t('FEED.FIELD_PROTOCOL_PARAMS_SECURE')"
                    class="mt-0"
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row class="mt-0 mb-0" v-if="protocol_params.secure">
                <v-col cols="12" class="pt-0 mt-0">
                  <v-textarea
                    v-model="protocol_params.secureOptions"
                    @change="$emit('input', {protocol_params: {secureOptions: $event}})"
                    :label="$t('FEED.FIELD_PROTOCOL_PARAMS_SECURE_OPTION')"
                    :placeholder="$t('FEED.FIELD_PROTOCOL_PARAMS_SECURE_OPTION')"
                    :disabled="!protocol_params.secure"
                    outlined
                    hide-details
                    dense
                  />
                </v-col>
              </v-row>
            </div>

            <!-- Upload -->
            <div v-if="protocol === 'upload'">
              <p class="mt-4 mb-0">{{$t('FEED.PROTOCOL_UPLOAD_TEXT')}}</p>
              <small class="grey--text">{{$t('FEED.PROTOCOL_PARAMS_FILE_TYPE_WARNING')}}</small>
              <v-row class="ma-0 pt-4" v-if="protocol_params.filename && values.protocol === 'upload'">
                <v-col cols="12" class="pa-0">
                  <v-list-item>
                    <v-list-item-avatar class="primary">
                      <v-icon size="24" color="white">mdi-file</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{protocol_params.filename}}</v-list-item-title>
                      <v-list-item-subtitle><small><a :href="protocol_params.url" target="_blank">{{protocol_params.url}}</a></small></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <div class="text-nowrap">
                        <v-btn class="ml-2" icon @click="removeFiles"><v-icon>mdi-delete</v-icon></v-btn>
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-row class="ma-0" v-else>
                <v-col cols="12" class="pl-0">
                  <InputDropzone v-model="files" @change="uploadFiles">
                    <div class="text-center"><v-icon size="42" color="primary">mdi-download</v-icon></div>
                    <h4 class="primary--text">{{$t('FEED.PROTOCOL_DROPZONE_TEXT_1')}}</h4>
                    <small class="subtitle">{{$t('FEED.PROTOCOL_DROPZONE_TEXT_2')}}</small>
                  </InputDropzone>
                </v-col>
              </v-row>
            </div>

            <!-- API -->
            <div v-if="protocol === 'api'">
              <p class="mt-4 mb-0">{{ $t('FEED.PROTOCOL_API_TEXT') }}</p>
              <v-row class="ma-0 pt-4">
                <v-col cols="8" class="pa-0" >
                  <v-select
                    v-model="protocol_params.controller_name"
                    :items="apiControllerNames"
                    :label="$t('FEED.FIELD_PROTOCOL_API_CONTROLLER_NAME')"
                    :rules="[$rules.required]"
                    required
                    hide-details
                    outlined
                    large
                  >
                  <template slot="selection" slot-scope="data">
                    <img :src="getApiImage(data.item.value)" width="50px" class="mr-4" /> {{ data.item.text }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <img :src="getApiImage(data.item.value)" width="50px" class="mr-4" /> {{ data.item.text }}
                  </template>
                  </v-select>
                </v-col>
              </v-row>

              <div v-if="protocol_params.controller_name === 'Cegid'">

                <v-row class="ma-0 pt-4">
                  <v-col cols="12" class="pa-0" >
                    <v-text-field
                      v-model="protocol_params.base_url"
                      label="API URL Endpoint"
                      placeholder="https://www.myapi.com/"
                      @keyup.enter="validate"
                      :rules="[$rules.required]"
                      clearable
                      outlined
                      dense
                      hide-details
                    />
                  </v-col>
                </v-row>

                <v-row class="ma-0 pt-4">
                  <v-col cols="6" class="pa-0 pr-4" >
                    <v-text-field
                      v-model="protocol_params.client_id"
                      label="Client_id"
                      placeholder="Client_id"
                      @keyup.enter="validate"
                      :rules="[$rules.required]"
                      clearable
                      outlined
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col cols="6" class="pa-0" >
                    <v-text-field
                      v-model="protocol_params.client_secret"
                      type="password"
                      label="Secret"
                      placeholder="Secret"
                      @keyup.enter="validate"
                      :rules="[$rules.required]"
                      clearable
                      outlined
                      dense
                      hide-details
                    />
                  </v-col>
                </v-row>

              </div>

            </div>

          </div>
        </v-card>
      </v-form>
    </v-dialog>

    <div>
      <span class="primary--text">{{$t('FEED.PROTOCOL_TITLE')}}</span>
      <v-btn
        small
        rounded
        depressed
        color="secondary black--text"
        class="ml-4 mr-4"
        @click="showDialog"
      >
        {{$t('COMMON.MODIFY')}}
      </v-btn>
      <span
        :class="[filledFieldsCount === 0 ? 'error--text' : 'grey--text text-medium']"
        v-if="values.protocol"
      >
        {{$tc('FEED.PROTOCOL_COUNT', filledFieldsCount)}}
      </span>
      <v-text-field
        name="form_fields"
        style="display:none;"
        :value="!!(values.protocol) || valid"
        :rules="[$rules.required]"
      />
    </div>

    <div class="mt-4">

      <v-divider />
      <v-row class="mt-0 mb-0 text-medium" v-if="values.protocol">
        <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('FEED.PROTOCOL')">
          {{$t('FEED.PROTOCOL')}}
        </v-col>
        <v-col cols="10" class="text-ellipsis text-nowrap">
          <span>{{values.protocol}}</span>
        </v-col>
      </v-row>

      <template v-if="values.protocol && values.protocol === 'upload'">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium" v-if="values.protocol_params && values.protocol_params.filename">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('FEED.FIELD_PROTOCOL_PARAMS_FILE')">
            {{$t('FEED.FIELD_PROTOCOL_PARAMS_FILE')}}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
              {{values.protocol_params.filename}}.{{values.fileformat}}
              <br />
              <a target="blank" :href="values.protocol_params.url"><small><v-icon class="mr-1" size="16">mdi-link</v-icon> {{values.protocol_params.url}}</small></a>
          </v-col>
        </v-row>
      </template>

      <template v-if="values.protocol && values.protocol === 'http'">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium" v-if="values.protocol_params && values.protocol_params.url">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('FEED.FIELD_PROTOCOL_PARAMS_URL')">
            {{$t('FEED.FIELD_PROTOCOL_PARAMS_URL')}}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <a target="blank" :href="values.protocol_params.url">
              <v-icon class="mr-1" size="18">mdi-link</v-icon>
              {{values.protocol_params.url}}
            </a>
          </v-col>
        </v-row>
      </template>

      <template v-if="values.protocol && values.protocol === 'ftp'">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium" v-if="values.protocol_params && values.protocol_params.host">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('FEED.FIELD_PROTOCOL_PARAMS_HOST')">
            {{$t('FEED.FIELD_PROTOCOL_PARAMS_HOST')}}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{values.protocol_params.host}}</span>
          </v-col>
        </v-row>
      </template>

      <template v-if="values.fileformat">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text" :title="$t('FEED.FIELD_FILEFORMAT')">
            {{$t('FEED.FIELD_FILEFORMAT')}}
          </v-col>
          <v-col cols="10" class="text-ellipsis text-nowrap">
            <span>{{values.fileformat.toUpperCase()}}</span>
          </v-col>
        </v-row>
      </template>

    </div>
  </div>
</template>

<script>
import InputDropzone from '@/components/InputDropzone'
import Loading from '@/components/Loading'

export default {
  name:"FormFeedCompactProtocol",
  components: {
    InputDropzone,
    Loading,
  },
  data: (_this) => ({
    loading: false,
    valid: false,
    dialog: false,
    protocolItems: [
      {text: _this.$i18n.t('FEED.FIELD_PROTOCOL_HTTP'), value: 'http'},
      {text: _this.$i18n.t('FEED.FIELD_PROTOCOL_FTP'), value: 'ftp'},
      {text: _this.$i18n.t('FEED.FIELD_PROTOCOL_UPLOAD'), value: 'upload'},
      {text: _this.$i18n.t('FEED.FIELD_PROTOCOL_API'), value: 'api'},
    ],
    protocolMethodItems: [
      {text: _this.$i18n.t('FEED.FIELD_PROTOCOL_METHOD_GET'), value: 'get'},
      {text: _this.$i18n.t('FEED.FIELD_PROTOCOL_METHOD_POST'), value: 'post'},
      {text: _this.$i18n.t('FEED.FIELD_PROTOCOL_METHOD_PUT'), value: 'put'},
      {text: _this.$i18n.t('FEED.FIELD_PROTOCOL_METHOD_PATCH'), value: 'patch'},
    ],
    protocolNeedAuth: false,
    protocolNeedData: false,
    //
    protocol: undefined,
    protocol_params: undefined,
    auto_refresh: undefined,
    legacy_html_to_md: undefined,
    max_churn_percent: undefined,
    // file
    files: undefined,
    fileError: false,
    apiControllerNames: [{text:"Cegid (TalentSoft)", value:"Cegid"}]
  }),
  props: {
    values: {
      type: [Object, undefined],
      default: () => ({})
    }
  },
  computed: {
    filledFieldsCount () {
      return +!!this.protocol
    },
  },
  watch: {
    values: {
      handler () {
        this.attribValues()
      },
      deep: true,
    },
  },
  created () {
    this.attribValues()
  },
  methods: {

    getApiImage(controller_name, size = 'original') {
      const url = `${process.env.VUE_APP_ASSETS}/apis/`
      return `${url + controller_name.toLowerCase()}.png`
    },

    removeFiles () {
      this.protocol_params = {}
      // this.$emit('input', {protocol: this.protocol, protocol_params: this.protocol_params})
    },

    uploadFiles () {
      this.loading = true
      const values = {files:this.files}
      this.$services.api_programmatic.feed.upload({values})
        .then(response => {
          if (response.data) {
            const keys = Object.keys(response.data)
            if (keys.length > 0) {
              const filename = keys[0]
              const url = process.env.VUE_APP_FILESHARE_PUBLIC + '/' + response.data[keys[0]] // full url
              this.protocol_params = {filename, url}
              this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 })
              // this.$emit('input', {protocol: this.protocol, protocol_params: this.protocol_params})
            }
          } else {
            this.$store.dispatch('messenger/add', {type: 'warning', code: 'MESSAGE.FEED.INVALID_FILE', message: 'Uploaded file is empty or not valid.', time: 3000 })
          }
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 3000 })
        })
        .finally(() => {
          this.loading = false
        })
    },

    // form fields
    apply () {
      if (this.$refs.form_protocol.validate()) {
        this.$emit('input', {protocol: this.protocol, protocol_params: this.protocol_params})
        this.dialog = false
      }
    },
    closeDialog () {
      this.dialog = false
    },
    showDialog () {
      this.fileformat_fields_form = this.$clone(this.fileformat_fields)
      this.dialog = true
    },

    attribValues () {
      const values = this.$clone(this.values)
      Object.keys(values).forEach(key => {
        if (key === 'protocol_params' && values[key]) {
          if (values[key].user || values[key].password || values[key].auth) this.protocolNeedAuth = true
          if (values[key].data) this.protocolNeedData = true
        }
        this[key] = values[key]
      })
    }
  }
}
</script>
